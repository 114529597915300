import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserTypeService } from '../services/user-type.service';
@Injectable({
  providedIn: 'root'
})
export class SecurityGuard implements CanActivate {
  constructor( private userType: UserTypeService) {}
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let role = this.userType.Security()
      if (role) {
        return true;
      } else {
        // this.snack.open('You do not have access to this page!', 'OK');
        return false;
      }
  }
  
}
