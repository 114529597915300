import { Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {
  title;
  message;
  loading = true;
  isMobile: boolean = false;
  isLaptop: boolean = false;
  isTablet: boolean = false;
  isScreen: boolean = false;
  closeSideBar: boolean = false;
  public layoutConf: any = {};
  private layoutConfSub: Subscription;

  constructor(private layouts: LayoutService) {
    this.layouts.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });

  }
  ngOnInit() {
    this.layoutConfSub = this.layouts.layoutConf$.subscribe(async (layoutConf) => {
      this.layoutConf = layoutConf
      this.closeSideBar = (this.layoutConf.sidebarStyle == 'compact') ? true : false;
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 3000)
  }

}
