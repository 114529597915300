import { Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { UserStateModel } from "./user.model";
import { User } from './user.action'
import { AuthService } from "@auth0/auth0-angular";
import { ApiService } from "app/shared/services/api.service";
import { LocalStoreService } from "app/shared/services/local-store.service";
@State<UserStateModel>({
    name: "user",
    defaults: {
        User: null,
    },
})
@Injectable({ providedIn: 'root' })
export class UserState implements NgxsOnInit {
    constructor(private auth: AuthService, private ls: LocalStoreService) {
    }
    ngxsOnInit(ctx: StateContext<UserStateModel>) {
        let user = this.ls.getItem('USER');
        ctx.patchState({
            User: user,
        });
        ApiService.org_id = user?.org_id;
        ApiService.sub = user?.sub;

    }
    @Selector()
    static user(state: UserStateModel) {
        return state;
    }
    @Selector()
    static users(state: UserStateModel) {
        return state.User;
    }
    @Action(User.FetchUser)
    FetchUser({ patchState }: StateContext<UserStateModel>) {
        this.auth.user$.subscribe((user) => {
            ApiService.org_id = user?.org_id;
            ApiService.sub = user?.sub;
            patchState({
                User: user,
            });
        });
    }
    @Action(User.AddUserFormSubmitted)
    AddUserFormSubmitted({ getState, patchState }: StateContext<UserStateModel>, action: User.AddUserFormSubmitted) { 
        ApiService.org_id = action.payload?.user?.org_id;
        ApiService.sub = action.payload?.user?.sub;
        const state = getState();
        patchState({
            User: action.payload?.user,
        });


    }
}