import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { Subscription } from "rxjs";
import { ThemeService } from "../../../shared/services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { ApiService } from "app/shared/services/api.service";
@Component({
  selector: "app-header-top",
  templateUrl: "./header-top.component.html",
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  Themes: any[] = [];
  currentService = 1;
  @Input() notificPanel;
  services$: any[] = [];
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public api: ApiService
  ) {
    this.currentService = this.api.serviceId;

  }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.Themes = this.themeService.Themes;
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      res = res.filter(
        (item) => item.type !== "icon" && item.type !== "separator"
      );
      let limit = 4;
      let mainItems: any[] = res.slice(0, limit);
      if (res.length <= limit) {
        return (this.menuItems = mainItems);
      }

      let subItems: any[] = res.slice(limit, res.length - 1);
      mainItems.push({
        name: "More",
        type: "dropDown",
        tooltip: "More",
        icon: "more_horiz",
        sub: subItems,
      });
      this.menuItems = mainItems;
      return true;
    });
  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }
  setService() {
    this.api.serviceId = this.currentService;
    this.api.publishSomeData({ serviceId: this.currentService });

    this.services$[this.currentService].serviceName;
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name });
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }
}
