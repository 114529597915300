<div class="sidenav-hold full-height" [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
    <div class="icon-menu mb-16" *ngIf="hasIconTypeMenuItem">
        <!-- Icon menu separator -->
        <!-- <div class="mb-16 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
    </div> -->
        <!-- Icon menu items -->
        <div class="icon-menu-item" *ngFor="let item of menuItems">
            <button *ngIf="!item.disabled && item.type === 'icon'" mat-raised-button [matTooltip]="item.tooltip"
                routerLink="/{{item.state}}" routerLinkActive="mat-bg-primary" fxLayout="row">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">
                    {{item.icon}}
                </mat-icon>
                <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
            </button>
        </div>
    </div>
    <ul appDropdown class="sidenav full-height" fxLayout="column">
        <li class="full-width" *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open"
            [ngClass]="{'lap':isLaptop,'tab':isTablet,'mob':isMobile,'scr':isScreen,'profile':item.name === 'Porfile'}">
            <!-- separator -->
            <div class="nav-item-sep" *ngIf="item.type === 'separator'">
                <mat-divider></mat-divider>
                <span class="text-muted">{{item.name }}</span>
            </div>
            <!-- Item -->
            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
                <a routerLink="/{{item.state}}" appDropdownToggle matRipple
                    *ngIf="item.type === 'link' && item.name !=='Porfile'">
                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon profile">
                        {{item.icon}}
                    </mat-icon>
                    <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
                    <span class="item-name lvl1"
                        [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                        {{item.name }}
                    </span>
                    <span fxFlex></span>
                    <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                        *ngFor="let badge of item.badges">
                        {{ badge.value }}
                    </span>
                </a>
                <a routerLink="/{{item.state}}" appDropdownToggle matRipple
                    *ngIf="item.type === 'link'  && item.name ==='Porfile'">
                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">
                        {{item.icon}}
                    </mat-icon>
                    <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
                    <span class="item-name lvl1"
                        [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                        {{item.name }}
                    </span>
                    <span fxFlex></span>
                    <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                        *ngFor="let badge of item.badges">
                        {{ badge.value }}
                    </span>
                </a>
                <a href="javascript:void(0)" matRipple *ngIf="item.type === 'button'" (click)="logout()">
                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">
                        {{item.icon}}
                    </mat-icon>
                    <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
                    <span class="item-name lvl1"
                        [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                        {{item.name }}
                    </span>
                    <span fxFlex></span>
                    <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                        *ngFor="let badge of item.badges">
                        {{ badge.value }}
                    </span>
                </a>
                <a [href]="item.state" appDropdownToggle matRipple *ngIf="item.type === 'extLink'" target="_blank">
                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">
                        {{item.icon}}
                    </mat-icon>
                    <span class="item-name lvl1"
                        [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                        {{item.name }}
                    </span>
                    <span fxFlex></span>
                    <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                        *ngFor="let badge of item.badges">
                        {{ badge.value }}
                    </span>
                </a>
                <!-- DropDown -->
                <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple href="javascript:void(0)">
                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">
                        {{item.icon}}
                    </mat-icon>
                    <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
                    <span class="item-name lvl1"
                        [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                        {{item.name }}
                    </span>
                    <span fxFlex></span>
                    <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                        *ngFor="let badge of item.badges">
                        {{ badge.value }}
                    </span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                </a>
                <!-- LEVEL 2 -->
                <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
                    <li *ngFor="let itemLvL2 of item.sub" class="itemLvL2" (click)="print(item)"
                        [ngClass]="itemLvL2.state === '' && itemLvL2.state  !=='roles' && router.url ==='/'+item.state ? 'open':''"
                        appDropdownLink routerLinkActive="open">
                        <a class="alvl2" routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                            appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                            <!-- <img *ngIf="itemLvL2.flag" [src]="itemLvL2.flag" class="flag" width="24px" height="24px"> -->
                            <mat-icon *ngIf="itemLvL2.svgIcon" [svgIcon]="itemLvL2.svgIcon" class="svgIcon"></mat-icon>

                            <span class="item-name lvl2"
                                [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                                {{itemLvL2.name }}
                            </span>
                            <span fxFlex></span>
                        </a>
                        <a class="alvl2 drop" *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                            <mat-icon *ngIf="itemLvL2.svgIcon" [svgIcon]="itemLvL2.svgIcon" class="svgIcon"></mat-icon>

                            <span class="item-name lvl2"
                                [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                                {{itemLvL2.name }}
                            </span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>
                        <!-- LEVEL 3 -->
                        <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                            <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                                <a class="alvl3"
                                    routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{itemLvL3.state}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3"
                                        [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
                                        {{itemLvL3.name }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>