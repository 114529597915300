import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, createSelector } from "@ngxs/store";
import { RolesStateModel } from "./role.model";
import { Role } from './role.action'
import { tap } from "rxjs/operators";
import { ApiService } from "app/shared/services/api.service";
@State<RolesStateModel>({
    name: "role",
    defaults: {
        Roles: [],
    },
})
@Injectable()
export class RoleState {
    constructor(private api: ApiService) {
    }
    @Selector()
    static role(state: RolesStateModel) {
        return state;
    }
    @Selector()
    static Roles(state: RolesStateModel) {
        return state.Roles;
    }
    @Action(Role.FetchRoles)
    FetchRoles({ getState, patchState }: StateContext<RolesStateModel>, action: Role.FetchRoles) {
        return this.api.getRoles(action.payload.orgId, action.payload.userId).pipe(tap((result) => {
            const state = getState();
            patchState({
                Roles: result?.body?.data,
            });
        }));
    }
    @Action(Role.AddRoleFormSubmitted)
    AddRoleFormSubmitted({ getState, patchState }: StateContext<RolesStateModel>, action: Role.AddRoleFormSubmitted) { 
        const state = getState();
        patchState({
            Roles: action.payload?.Role,
        });


    }


    static RoleByName(name: string) {
        return createSelector([RoleState], (state: RolesStateModel) => {
            return state.Roles.find((role) => role.name === name);
        });
    }
}