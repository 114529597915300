import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStoreService } from '../services/local-store.service';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class VIPGuard implements CanActivate {

  constructor( private ls: LocalStoreService, private snack: MatSnackBar) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    // console.log(state);
    // console.log(route);
    let vip = this.ls.getItem('VIP')
    if (vip) {
      return true;
    } else {
      this.snack.open('You do not have access to this page vip!', 'OK');
      return false;
    }
  }
}
