import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStoreService } from '../services/local-store.service';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ReservedGuard implements CanActivate {
  constructor(private ls: LocalStoreService, private snack: MatSnackBar) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(state);
    console.log(route);
    let reserved = this.ls.getItem('RESERVED')
    if (reserved) {
      return true;
    } else {
      this.snack.open('You do not have access to this page reservd!', 'OK');
      return false;
    }
  }
}
