// export enum LINE_CHART_TYPE {
//   YEAR = 0,
//   MONTH = 1,
//   WEEK = 2,
// }
export enum DEVICE_INNER_WINDOW_SIZE {
  SCREEN = 2880,
  LABTOP = 1023,
  TABLET = 720,
  MOBILE = 0,
}


// export enum DASHBOARD_TYPE {
//   DAILY = 0,
//   WEEKLY = 1,
//   MONTHLY = 2,
//   YEARLY = 3,
//   CUSTOM = 4,
// }



export enum CURRENCY {
  'AED',
}


// export enum PaymentMethod {
//   Other = 0,
//   Cash = 1,
//   Cards = 2,
//   CUSTOM = 3,
//   MULTIPLE = 4
// }
export enum ACTIONS {
  EDIT_PERMISSIONS = 'edit_permissions',
  EDIT = 'edit',
  EDIT_PARK = 'edit_park',
  SHOW_PAYMENTS = 'payment',
  VIEW = 'view details',
  SAVE = 'save',
  DELETE = 'delete',
  ASSING_ROLE = 'Assign Role',
  CLOSE_TICKET = 'Close Ticket'
}
export enum Lists {
  ALL = 'ALL',
  WHITELISTED = 'WHITELISTED',
  UN_WHITELISTED = 'UN_WHITELISTED'
}
export enum Lang {
  ARABIC = 'Arabic',
  ENGLISH = 'English'
}
export enum AraLang {
  ARABIC = 'العربية',
  ENGLISH = 'الأنكليزية'
}
export enum Roles {
  SUPERADMIN = 'Super Admin',
  ADMIN = 'Administrator',
  OPERATIONMANAGER = 'Operation Manager',
  SECURITYOPERATER = 'Security Operator',
  ACCOUNTANT = 'Accountant',
  CASHIER = 'Cashier',
}