import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SearchParamCount } from "../models/dataTable.model";
import { LocalStoreService } from "./local-store.service";
@Injectable({
  providedIn: "root",
})
export class FilterService {
  f = new Date();
  t = new Date();
  private SearchFilter$ = new BehaviorSubject<SearchParamCount>({
    fromDate: this.f.toISOString(),
    toDate: new Date().toISOString(),
    fromTime: 0,
    toTime: Number(new Date().getHours()),
    site: this.ls.getItem("SITE"),
    lane: "",
    paymentMethod: ''
  });
  public publishFilterData(data: SearchParamCount) {
    this.SearchFilter$.next(data);
  }
  public getSearchObservable(): Observable<SearchParamCount> {
    return this.SearchFilter$;
  }
  public publishFilterBoundedData(data: SearchParamCount) {
    this.SearchFilter$.next(data);
  }
  public getSearchBoundedObservable(): Observable<SearchParamCount> {
    return this.SearchFilter$;
  }
  constructor(private ls: LocalStoreService) {
    this.f.setHours(0, 0, 0, 0);
  }
}
