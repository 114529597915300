import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appThousandSuffix]'
})
export class ThousandSuffixDirective {
  constructor(private el: ElementRef) { }

  @Input('appThousandSuffix') value: any;
  ngOnInit() {
    this.convertValue();
  }

  private convertValue() {
    let newValue: number;

    if (typeof this.value === 'number') {
      newValue = this.value;
    } else if (typeof this.value === 'string') {
      newValue = parseFloat(this.value.replace(/[^\d\.]/g, ''));
    }

    if (!isNaN(newValue) && newValue > 1000) {
      newValue = Math.round(newValue / 1000);
      this.el.nativeElement.textContent = newValue + 'K';
    }
  }
}
