import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "app/shared/services/api.service";
import { AppLogoutService } from "app/shared/services/app-logout/app-logout.service";
import { LayoutService } from "app/shared/services/layout.service";
import { LocalStoreService } from "app/shared/services/local-store.service";
@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.template.html",
})
export class SidenavComponent {
  @Input("items") public menuItems: any[] = [];
  @Input("hasIconMenu") public hasIconTypeMenuItem: boolean;
  // @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  isMobile: boolean;
  isLaptop: boolean;
  isTablet: boolean;
  isScreen: boolean;
  URL = '';
  constructor(
    public router: Router,
    public api: ApiService,
    private logoutService: AppLogoutService,
    private ls: LocalStoreService,
    private layout: LayoutService) {

    this.layout.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });
    this.URL = '';
  }
  ngOnInit() {
    this.URL = window.location.pathname;
  }
  print(item) {
    if (this.router.url === "/" + item.state) {
      ////console.log("OKKKK");
    } else {
      ////console.log("Nooo");
    }
    ////console.log("item", "/" + item.state);
    ////console.log(name.state);
  }
  logout() {
    this.logoutService
      .confirm({ message: `Are you sure you want to logout?` })
      .subscribe((res) => {
        if (res) {
          this.ls.clear();
          this.api.logout();
        }
      });
  }
  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: "Validated List",
      type: "link",
      tooltip: "Validated List",
      state: "validate",
      svgIcon: "lists",
    });
  }
}