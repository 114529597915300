import { Component, HostListener, OnInit } from '@angular/core';
import { LayoutService } from 'app/shared/services/layout.service';
@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {
  public innerWidth: number = 0;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isScreen: boolean;
  constructor(
    public layout: LayoutService
  ) {
    this.layout.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });
  }

  ngOnInit() {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log(event)
  }


}
