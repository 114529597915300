import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/shared/services/api.service';
import * as APICode from "app/shared/enums/api-codes";
import { Gates } from 'app/shared/models/site.model';
import { ToastrService } from 'ngx-toastr';
import { SingleTonService } from 'app/shared/services/single-ton.service';

@Component({
  selector: 'app-show-gate-lists',
  templateUrl: './show-gate-lists.component.html',
  styleUrls: ['./show-gate-lists.component.scss']
})
export class ShowGateListsComponent implements OnInit {
  @Input() openDialog =false;
  @Input() site; 
  @Input() filterGates =[]; 
  areaType ='';
  @Input() gateType='';
  gates:Gates[]=[];
  gatesCopy:Gates[]=[];
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  loading=false;

  constructor(
    private api:ApiService,
    private toastr:ToastrService,
    private singleTon:SingleTonService
  ) {
    this.areaType = this.singleTon.type
   }

  ngOnInit(): void {
  }
  ngOnChanges(){
    if(this.openDialog && this.site !=='ALL')
      this.getGates(this.site.id, this.gateType,this.areaType);
  }
  getGates(site,gateType,areaType){
    this.loading = true;
    this.api.getSiteById(site,gateType,areaType).subscribe(res => {
      this.loading = false; 
      if (res.status == APICode.API_CODES.SUCCESS) {
        let gates: Gates[] = [];
        let data = res.body.data;
        data.forEach(item => {
          let gate: Gates = {
            name: item.name,
            gateId: item.gateId,
            ipAddress: item.ipAddress,
            laneName: item.laneName,
            provider: item.provider,
            areaType: item.areaType,
            IP: item.barrierIp,
            gateRole:item.gateRole,
            linkedTo:item.linkedTo?item.linkedTo:[],
            code:item.code,
            gateType:item.gateType,
            url:item.url,
            lat: item.lat,
            long: item.long
          };
          gates.push(gate)
        }) 

        this.gates = []
        this.gates = gates;
        if (this.filterGates.length>0) {
          const gatesCopy = []
          this.gates?.forEach(ele => {
            if (this.filterGates?.includes(ele.code))
              gatesCopy.push(ele)
          });
          this.gatesCopy = gatesCopy
        }
      } else {
        this.loading = false;
        this.toastr.error(res?.errorCode ? res?.error : 'Un expected error', 'Error!!', { timeOut: 1000 })
      }
    })
  }
  closeModal() {
    this.close.emit({ edit: false });

  }
  closeModalTouch(event: any) {
    event.stopPropagation();
    this.close.emit({ edit: false });
  }
}
