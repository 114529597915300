import { Page } from "app/shared/models/common.model";
import { Sites } from "app/shared/models/site.model";

export namespace Site {
    export class AppLoaded {
        static readonly type = "[App] App Loaded";
    }
    export class SelectSite {
        static readonly type = "[Add Site Page] Add Site Form Submitted";
        constructor(public payload: { Site: Sites | any }) { }
    }
    export class FetchSites {
        static readonly type = "[Add Sites Page] Add Sites Form Api";
        constructor(public payload: { areaType: string, page: Page }) { }
    }
    export class GetSites {
        static readonly type = "[Add Sites Page] Add Sites Form Submit";
        constructor(public payload: { Sites: Sites | any[] }) { }
    }


}