<ngx-datatable
    #myTable
    [ngClass]="{'fullcam':(layouts.layoutConf.sidebarStyle==='compact')&& (title==='cam'),
    'full':(layouts.layoutConf.sidebarStyle==='compact')&& (title!=='cam'),'colsedcam':(layouts.layoutConf.sidebarStyle==='full')&& (title==='cam')
    ,'compact':(layouts.layoutConf.sidebarStyle==='full')&& (title!=='cam'),'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}"
    class="material ml-0 mr-0"
    [rows]="items"
    [columnMode]="'force'"
    [headerHeight]="isScreen?56:36"
    [footerHeight]="isScreen?56:36"
    [loadingIndicator]="loading"
    [externalPaging]="true"
    [count]="page.totalDocs"
    [offset]="page.page"
    [limit]="page.limit"
    (page)="setPage($event)"
    [rowClass]="getRowClass"
    [rowHeight]="isScreen?56:36"
    sortable="true"
>
    <ngx-datatable-row-detail
        [rowHeight]="isScreen?56:36"
        #myDetailRow
    >
        <ng-template
            let-row="row"
            let-expanded="expanded"
            let-rowIndex="rowIndex"
            ngx-datatable-row-detail-template
        >
            <div
                class="rowDetails"
                [ngClass]="{'open': (hide  && rows===row),'close':(!hide && rows===row)}"
                style="padding:0px 10px;font-size:12px"
            >
                <div
                    fxLayout="row wrap"
                    fxLayoutAlign="space-between center"
                    *ngFor="let col of columns;let i=index"
                >
                    <div
                        class="m-b-3"
                        fxFlex="100"
                        fxLayout="row"
                        *ngIf="i<=2  && col.col !='img'"
                        fxLayoutAlign="space-between center"
                        fxLayoutGap="10px"
                    >
                        <span
                            class="sembold"
                            fxLayoutAlign="start center"
                            fxFlex="45"
                        >
                            {{replace(col.col)}}
                        </span>
                        <span
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            fxFlex="45"
                            [matTooltip]="row[col.col]"
                        >
                            {{ row[col.col] }}
                        </span>
                    </div>
                    <div
                        class="m-b-3"
                        fxFlex="100"
                        fxLayout="row"
                        *ngIf="i>2  && hide && rows===row && col.col !='img'"
                        fxLayoutAlign="space-between center"
                        fxLayoutGap="10px"
                    >
                        <span
                            class="sembold"
                            fxLayoutAlign="start center"
                            fxFlex="45"
                        >
                            {{replace(col.col)}}
                        </span>
                        <span
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            fxFlex="45"
                            [matTooltip]="row[col.col]"
                        >
                            {{ row[col.col] }}
                        </span>
                    </div>
                    <div
                        class="m-b-3"
                        fxFlex="100"
                        fxLayout="row"
                        *ngIf="hide && rows===row && col.col ==='img'"
                        fxLayoutAlign="space-between center"
                        fxLayoutGap="10px"
                    >
                        <span
                            class="sembold"
                            fxLayoutAlign="start center"
                            fxFlex="45"
                        >
                            {{replace(col.col)}}
                        </span>
                        <span
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            fxFlex="45"
                        >
                            <img
                                *ngIf="row[col.col]"
                                [src]="row[col.col]"
                                width="50px"
                                style="max-width:50px !important;height:30px;border-radius: 3px;"
                            >
                        </span>
                    </div>
                    <div
                        class="m-b-3"
                        fxFlex="100"
                        fxLayout="row"
                        *ngIf="hide && rows===row && col.col ==='img' && action"
                        fxLayoutAlign="space-between center"
                        fxLayoutGap="10px"
                    >
                        <span
                            class="sembold"
                            fxLayoutAlign="start center"
                            fxFlex="45"
                        >
                            ACTIONS
                        </span>
                        <span
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            fxFlex="45"
                        >
                            <div class="btns">
                                <button
                                    mat-icon-button
                                    *ngFor="let action of ActionsBtns; let i = index"
                                    (click)="TriggerAction(action, row)"
                                    [matTooltip]="action.text"
                                    class="edituser"
                                >
                                    <img
                                        width="33px"
                                        [src]="action.icon"
                                    >
                                </button>
                            </div>
                        </span>
                    </div>
                </div>
                <div
                    fxLayout="row"
                    fxLayoutAlign="end center"
                >
                    <span
                        (click)="onHide(row)"
                        matSuffix
                        class="moreInfo"
                    >
                        {{(hide && rows===row)?'View less':'View
                        all'}}
                    </span>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column
        [flexGrow]="2"
        *ngFor="let col of columns"
        [name]="col.name"
        [class]="col.col"
        [prop]="col.col"
        [minWidth]="layouts.layoutConf.sidebarStyle==='compact'?col.width.minOpen:col.width.minClose"
        [ngStyle]="col.col==='img'?{padding:'5px 5px 10px 0px'}:{}"
    >
        <ng-template
            let-row="row"
            ngx-datatable-cell-template
        >
            <ng-container>
                <span
                    *ngIf="col.col !=='img'"
                    class="mobile-hidden"
                    [matTooltip]="row[col.col]"
                >
                    {{ row[col.col]
                    }}
                </span>
                <span
                    *ngIf="col.col ==='img'"
                    class="mobile-hidden"
                >
                    <img
                        *ngIf="row.img"
                        [src]="row.img"
                        width="100"
                        [ngStyle]="layouts.layoutConf.sidebarStyle!=='compact'?{'max-width':'100%',height:'25px'}:{'max-width':'100%',height:'25px'}"
                    >
                    <span *ngIf="!row.img">No Image</span>
                </span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
        *ngIf="action"
        name
    >
        <ng-template
            let-row="row"
            ngx-datatable-cell-template
            class="action"
        >
            <div class="btns mobile-hidden">
                <button
                    mat-icon-button
                    *ngFor="let action of ActionsBtns; let i = index"
                    (click)="TriggerAction(action, row)"
                    [matTooltip]="action.text"
                    class="edituser"
                >
                    <img
                        width="33px"
                        [src]="action.icon"
                    >
                </button>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
