import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import {
  IMenuItem,
  NavigationService,
} from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { LocalStoreService } from "app/shared/services/local-store.service";
import { ApiService } from "app/shared/services/api.service";
import { Store } from "@ngxs/store";
import { RoleState, SiteState } from 'app/core'
import { SingleTonService } from "app/shared/services/single-ton.service";
import { combineLatest } from 'rxjs';
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  AllData = this.ls.getItem("PUBLICData");
  publicDate = this.ls.getItem("PUBLIC");
  reserved = this.ls.getItem("RESERVED");
  community = this.ls.getItem("COMMUNITY");
  vip = this.ls.getItem("VIP");
  CurrentType = "";
  Parkdata = []
  isMobile: boolean;
  isLaptop: boolean;
  isTablet: boolean;
  isScreen: boolean;
  MenuIcon: IMenuItem[] = []
  Sites: any[];
  typeMenu = '';
  role;
  type;
  site;
  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    private ls: LocalStoreService,
    private store: Store,
    private singleton: SingleTonService,
    private route: Router

  ) {
    this.type = this.singleton.type
    combineLatest([
      this.store.select(RoleState.Roles),
      this.store.select(SiteState.site),
    ]).subscribe(([firstStateValue, secondStateValue]) => {
      this.role = firstStateValue; 
      this.site = secondStateValue;
      console.log(firstStateValue, secondStateValue);
      this.initSideBare(firstStateValue[0]?.name);
      // if (this.site.site === 'ALL') {
      //   this.navService.publishNavigationChange('overview-menu');
      //   if (!this.route.url.includes('tenant'))
      //     this.route.navigate(['tenant'])
      // }
    })
    this.MenuIcon = this.navService.iconMenu.slice()
    this.layout.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });
    this.layout.getParkObservable().subscribe((val) => {
      if (val.length > 0) {
        this.Parkdata = val;
      }
    });
    this.layout.getCurrentTypeObservable().subscribe(ele => {
      this.CurrentType = ele.type;
      this.type = ele.type;
      this.publicDate = this.ls.getItem("PUBLIC");
      this.reserved = this.ls.getItem("RESERVED");
      this.vip = this.ls.getItem("VIP");
      this.community = this.ls.getItem("COMMUNITY");
      console.log(this.role[0]?.name)
      this.initSideBare(this.role[0]?.name);
    });

  }
  ngOnInit() {
    this.AllData = this.ls.getItem("PUBLICData");
    this.publicDate = this.ls.getItem("PUBLIC");
    this.reserved = this.ls.getItem("RESERVED");
    this.vip = this.ls.getItem("VIP");
    this.community = this.ls.getItem("COMMUNITY");
    this.CurrentType = ApiService.typeData
    this.layoutConf = this.layout.layoutConf;

  }
  ngOnChanges() {
  }
  ngAfterViewInit() { }
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: true,
      });
    }
  }
  initSideBare(role?) {
    console.log(this.site?.site)
    if (role && role.toString().toLowerCase() !== "accountant" && this.site?.site !== 'ALL') {

      if (this.type ==='public' || this.type ==='gym') {
        this.typeMenu = 'public-menu'
        this.navService.publishNavigationChange(this.typeMenu)
        this.MenuIcon = this.navService.iconMenu.slice();

      }
      if (this.publicDate && this.vip || this.reserved) {
        this.typeMenu = 'whole-menu'
        this.navService.publishNavigationChange(this.typeMenu);
        this.MenuIcon = this.navService.wholeIconMenu.slice();

      }
      if (this.type === 'vip') {
        this.typeMenu = 'vip-menu'
        this.navService.publishNavigationChange(this.typeMenu);
        this.MenuIcon = this.navService.vipIconMenu.slice();
      }
      if (this.type === 'community') {
        this.typeMenu = 'community-menu'
        this.navService.publishNavigationChange(this.typeMenu);
        this.MenuIcon = this.navService.CommunityMenu.slice();
      }
      if (role === "Security Operator") {
        this.navService.setBooleanValue(true, this.typeMenu);
        console.log('security')
      }
    } else if (role && role.toString().toLowerCase() === "accountant" && this.site?.site !== 'ALL') {
      this.navService.publishNavigationChange('accountant-menu')
      this.MenuIcon = this.navService.AccountantMenu.slice()
    }else if(this.site?.site ===  'ALL'){
      this.navService.publishNavigationChange('overview-menu')
      this.MenuIcon = this.navService.iconOverviewMenu.slice()
      if (!this.route.url.includes('tenant'))
        this.route.navigate(['tenant'])
    }
    this.MenuIcon.filter((ele) => {
      if (ele.name === 'Home')
        ele.state = `Home/dashboard/${LayoutService.site?.site}`
    })
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      console.log(menuItem);
      this.menuItems = menuItem;
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon" || item.type === "flag"
      ).length;
    });
  }
}
