 <div (click)="closeModalTouch($event)" #myModal *ngIf="openDialog==true">
    <div (click)="$event.stopPropagation()" class="modal">
        <div  class="modal-content" > 
             <img srcset="assets/images/cloz.svg" width="26px" alt="close" (click)='closeModal()' class="cancelIcon">
            <h1 class="headerText">Gates List</h1>
            <div class="gateListBox">
                <mat-list>
                    <mat-list-item *ngFor="let gate of gatesCopy"><p>{{gate.laneName |titlecase}}</p></mat-list-item> 
                   </mat-list>
            </div>
     </div>
    </div>
</div>