import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from './services/theme.service';
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from './services/route-parts.service';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLogoutService } from './services/app-logout/app-logout.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { AppInputCloseService } from './services/app-Input-close/app-Input-close.service';
import { SharedMaterialModule } from './shared-material.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
//Guard
import { PublicGuard } from 'app/shared/guards/public.guard';
import { ReservedGuard } from 'app/shared/guards/reserved.guard';
import { AccountantGuard } from './guards/accountant.guard';
import { VIPGuard } from 'app/shared/guards/vip.guard';
import { SecurityGuard } from './guards/security.guard';
import { CashierGuard } from './guards/cashier.guard';
import { FilterService } from './services/filter.service';
import { UserTypeService } from './services/user-type.service';

import {
  StyleDefinition,
  LayoutGapParent,
  LayoutGapStyleBuilder,
} from '@angular/flex-layout';
import { AppInputCloseComponent } from './services/app-Input-close/app-Input-close.component';
import { FormsModule } from '@angular/forms';
@Injectable()
export class CustomLayoutGapStyleBuilder extends LayoutGapStyleBuilder {
  buildStyles(gapValue: string, parent: LayoutGapParent): StyleDefinition {
    return super.buildStyles(gapValue || '0 px', parent);
  }

  sideEffect(gapValue, _styles, parent) {
    return super.sideEffect(gapValue || '0 px', _styles, parent);
  }

}
@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    FormsModule,
    SharedMaterialModule],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AppConfirmService,
    AppLogoutService,
    AppLoaderService,
    AppInputCloseService,
    VIPGuard,
    SecurityGuard,
    CashierGuard,
    ReservedGuard,
    AccountantGuard,
    PublicGuard,
    FilterService,
    UserTypeService,
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder }
  ],
  exports: [
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
  ],
  declarations: [AppInputCloseComponent]
})
export class SharedModule { }
