import { Injectable } from "@angular/core";
import * as enums from 'app/shared/enums/enums';
import { LocalStoreService } from "./local-store.service";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import { Subject } from "rxjs";
@Injectable({ providedIn: 'root' })
export class UserTypeService {
  roles$ = new Subject<void>();
  rolesArray = []
  constructor(private ls: LocalStoreService, private api: ApiService) {
    if (!this.ls.getItem("Role")) {
      this.api
        .getRoles(ApiService.org_id, ApiService.sub)
        .pipe(map((x) => x.body))
        .subscribe((result) => {
          // console.log(result.data);
          if (result.success) {
            this.ls.setItem("Role", result.data);
            this.publishArray(Array.from(this.ls.getItem("Role")));
          }
        });
      this.getSearchObservable().subscribe(array => {
        this.rolesArray = array
      })
    } else {
      this.rolesArray = this.ls.getItem('Role')
    }
  }
  public publishArray(data: any) {
    this.roles$.next(data);
  }
  public getSearchObservable(): Subject<any> {
    return this.roles$;
  }
  SuperAdmin(): boolean {
    // console.log(this.rolesArray)
    if (
      this.rolesArray &&
      this.rolesArray.some((ele) => {
        return ele.name === enums.Roles.SUPERADMIN;
      })
    )
      return true;
    return false;
  }
  Admin(): boolean {
    if (
      this.rolesArray &&
      this.rolesArray.some((ele) => {
        return ele.name === enums.Roles.ADMIN;
      })
    )
      return true;
    return false;
  }
  Accountant(): boolean {
    if (
      this.rolesArray &&
      this.rolesArray.some((ele) => {
        return ele.name === enums.Roles.ACCOUNTANT;
      })
    )
      return true;
    return false;
  }
  OperationManager(): boolean {
    if (
      this.rolesArray &&
      this.rolesArray.some((ele) => {
        return ele.name === enums.Roles.OPERATIONMANAGER;
      })
    )
      return true;
    return false;
  }
  Security(): boolean {
    if (
      this.rolesArray &&
      this.rolesArray.some((ele) => {
        return ele.name === enums.Roles.SECURITYOPERATER;
      })
    )
      return true;
    return false;
  }
  Cashier(): boolean {
    if (
      this.rolesArray &&
      this.rolesArray.some((ele) => {
        console.log(ele)
        return ele.name === enums.Roles.CASHIER;
      })
    )
      return true;
    return false;
  }


}