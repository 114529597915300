import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStoreService } from '../services/local-store.service';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class PublicGuard implements CanActivate {

  constructor(private ls: LocalStoreService, private snack: MatSnackBar) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(state);
    console.log(route);
    let publicData = this.ls.getItem('PUBLIC')
    if (publicData) {
      return true;
    } else {
      this.snack.open('You do not have access to this page PUBLIC!', 'OK');
      return false;
    }
  }
}
