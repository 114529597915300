<mat-toolbar class="topbar mat-bg-card-toolbar pr-36">
    <button *ngIf=" (!login || login !=='login')" mat-icon-button id="sidenavToggle" (click)="toggleCollapse()"
        name="toggel" aria-label="toggel">
        <mat-icon style="color: #fff  !important; font-size: 23px;">menu</mat-icon>
    </button>

    <div class="globalSite mr-12" fxLayout="row" fxLayoutAlign="start center" style="width: fit-content"> 
        <ng-select placeholder="Select site ..." [(ngModel)]="CurrentSite" [searchable]="false" [clearable]="false"
            class="locationSelect" [items]="Sites" bindLabel="site" (change)="focusEvent($event)">
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngClass]="{'highlight-option': item.site === 'ALL'}">
                    {{ item.site }}
                </div>
            </ng-template>
        </ng-select>
        <ng-select *ngIf="Parkdata.length>1 && !isOrganization" [(ngModel)]="CurrentType" [searchable]="false" [clearable]="false"
            class="locationSelect" [items]="Parkdata" (change)="focusType($event)">
        </ng-select>
        <button *ngIf="Parkdata.length==1 && !isOrganization" mat-button fxLayout="row" fxLayoutAlign="center center"
            class="topbar-button-right img-button">
            <span>{{CurrentType|titlecase}}</span>
        </button>
    </div>
    <span fxFlex></span>


    <div class="profileBtn mr-12" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <img [matTooltip]="user?.nickname" style="    
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: url('../../../../assets/images/profile.png') contain;"
                [srcset]="user?.picture?user?.picture:'assets/images/profile.png'" alt="user picutre" />
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item class="info" disabled="true">
                <div fxLAyout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <img [matTooltip]="user?.nickname" style="    
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: url('../../../../assets/images/profile.png') contain;"
                        [srcset]="user?.picture?user?.picture:'assets/images/profile.png'" (error)="onError($event)"
                        alt="user picutre" />
                    <div fxFlex="80" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                        <span fxFlex="100" class="userInfoName">{{user?.nickname}}</span>
                        <span fxFlex="100" class="userInfoEmail">{{user?.email}}</span>
                    </div>

                </div>
            </button>
            <button mat-menu-item routerLink="/profile">
                <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <img srcset="assets/images/face.png" alt="profile">
                    <span>Profile setting</span>
                </div>
            </button>
            <button mat-menu-item (click)="logout()">
                <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <img srcset="assets/images/logout1.png" alt="profile">
                    <span>Sign out</span>
                </div>

            </button>
        </mat-menu>

        <!-- <span class="nickname" *ngIf="!isMobile">{{user?.nickname}}</span> -->
    </div>

</mat-toolbar>