import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
// import { LocalStoreService } from "./local-store.service";
export interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  flag?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService implements OnInit {
  isSecurity = false

  iconMenu: IMenuItem[] = [];
  vipIconMenu: IMenuItem[] = [];
  wholeIconMenu: IMenuItem[] = [];
  iconOverviewMenu: IMenuItem[] = [];
  AccountantMenu: IMenuItem[] = []; 
  CommunityMenu: IMenuItem[] = []; 

  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  // navigation component has subscribed to this Observable 
  private isSecuritySubject = new BehaviorSubject<boolean>(false);
  isSecuritySubject$ = this.isSecuritySubject.asObservable();

  setBooleanValue(value: boolean,menuType): void {
    this.isSecurity = value;
    this.getInitArrays()
    this.isSecuritySubject.next(value);
    this.publishNavigationChange(menuType,value)
  }
  getBooleanValue(): boolean {
    return this.isSecuritySubject.getValue();
  }
  constructor() { 
    this.getInitArrays();
  }

  ngOnInit() { 
    this.isSecuritySubject$.subscribe(isSecurity => {
      this.isSecurity = isSecurity;
      this.publishNavigationChange();
    });
  }
  getInitArrays(){
    this.iconMenu = [
      {
        name: "Home",
        type: "link",
        tooltip: "Overview",
        svgIcon: "home1",
        state: `Home`,
      }, 
      {
        name: "Analytics",
        type: "link",
        tooltip: "Analytics",
        state: "analytics",
        svgIcon: "analytic",
      },
  
      {
        name: "Camera records",
        type: "link",
        tooltip: "Camera requests",
        state: "camera-requests",
        svgIcon: "camera",
      },
      // {
      //   name: "Reserved Park",
      //   type: "link",
      //   tooltip: "Reserved Park",
      //   state: "reserved-park",
      //   svgIcon: "parkingfees",
      // },
      {
        name: "Open Transactions",
        type: "link",
        tooltip: "tickets",
        state: "tickets",
        svgIcon: "ulb_list",
      },
      {
        name: "System Configuration",
        type: "dropDown",
        tooltip: "System_Configuration",
        state: "System_Configuration",
        svgIcon: "ulb_menu",
        sub: [
          {
            name: "Users",
            type: "link",
            svgIcon: "usersNav",
            state: "users",
          },
          {
            name: "Roles",
            type: "link",
            svgIcon: "ulb_partnership_1",
            state: "roles",
          },
          {
            name: "Sites",
            type: "link",
            state: "sites",
            svgIcon: "sites",
          },
          {
            name: "Parking fees",
            type: "link",
            state: "parkingFees",
            svgIcon: "parkingfees",
          },
        ],
      },
      {
        name: "Access Control",
        type: "dropDown",
        tooltip: "access control",
        state: "access_control",
        svgIcon: "ulb_menu",
        sub: [
          ...(this.isSecurity)?[{
            name: "Validated List",
            type: "link",
            state: "validatedlist",
            svgIcon: "lists",
          }]:[],
          {
            name: "WhiteList",
            type: "link",
            state: "whitelist",
            svgIcon: "lists",
          },
          {
            name: "Blacklist",
            type: "link",
            state: "blacklist",
            svgIcon: "lists",
          },
        ],
      },
  
    ];
    this.vipIconMenu = [
      {
        name: "Home",
        type: "link",
        tooltip: "Overview",
        svgIcon: "home1",
        state: `Home`,
      },
  
      {
        name: "Analytics",
        type: "link",
        tooltip: "Analytics",
        state: "analytics",
        svgIcon: "analytic",
      },
  
      {
        name: "Camera records",
        type: "link",
        tooltip: "Camera requests",
        state: "camera-requests",
        svgIcon: "camera",
      }, 
      {
        name: "System Configuration",
        type: "dropDown",
        tooltip: "System_Configuration",
        state: "System_Configuration",
        svgIcon: "ulb_menu",
        sub: [
          {
            name: "Users",
            type: "link",
            svgIcon: "usersNav",
            state: "users",
          },
          {
            name: "Roles",
            type: "link",
            svgIcon: "ulb_partnership_1",
            state: "roles",
          },
          {
            name: "Sites",
            type: "link",
            state: "sites",
            svgIcon: "sites",
          },
        ],
      },
      {
        name: "Access Control",
        type: "dropDown",
        tooltip: "access control",
        state: "access_control",
        svgIcon: "ulb_menu",
        sub: [
          ...(this.isSecurity)?[{
            name: "Validated List",
            type: "link",
            state: "validatedlist",
            svgIcon: "lists",
          }]:[],
          {
            name: "VIP WhiteList",
            type: "link",
            state: "vip_whitelist",
            svgIcon: "lists",
          },
          {
            name: "Blacklist",
            type: "link",
            state: "blacklist",
            svgIcon: "lists",
          },
        ],
      },
  
    ];
    this.wholeIconMenu = [
      {
        name: "Home",
        type: "link",
        tooltip: "Overview",
        svgIcon: "home1",
        state: `Home`,
      },
  
      {
        name: "Analytics",
        type: "link",
        tooltip: "Analytics",
        state: "analytics",
        svgIcon: "analytic",
      },
  
      {
        name: "Camera records",
        type: "link",
        tooltip: "Camera requests",
        state: "camera-requests",
        svgIcon: "camera",
      },
      // {
      //   name: "Reserved Park",
      //   type: "link",
      //   tooltip: "Reserved Park",
      //   state: "reserved-park",
      //   svgIcon: "parkingfees",
      // },
      {
        name: "Open Transactions",
        type: "link",
        tooltip: "tickets",
        state: "tickets",
        svgIcon: "ulb_list",
      },
      {
        name: "System Configuration",
        type: "dropDown",
        tooltip: "System_Configuration",
        state: "System_Configuration",
        svgIcon: "ulb_menu",
        sub: [
          {
            name: "Users",
            type: "link",
            svgIcon: "usersNav",
            state: "users",
          },
          {
            name: "Roles",
            type: "link",
            svgIcon: "ulb_partnership_1",
            state: "roles",
          },
          {
            name: "Sites",
            type: "link",
            state: "sites",
            svgIcon: "sites",
          },
          {
            name: "Parking fees",
            type: "link",
            state: "parkingFees",
            svgIcon: "parkingfees",
          },
        ],
      },
      {
        name: "Access Control",
        type: "dropDown",
        tooltip: "access control",
        state: "access_control",
        svgIcon: "ulb_menu",
        sub: [
          ...(this.isSecurity)?[{
            name: "Validated List",
            type: "link",
            state: "validatedlist",
            svgIcon: "lists",
          }]:[],
          {
            name: "VIP WhiteList",
            type: "link",
            state: "vip_whitelist",
            svgIcon: "lists",
          },
  
          {
            name: "WhiteList",
            type: "link",
            state: "whitelist",
            svgIcon: "lists",
          },
          {
            name: "Blacklist",
            type: "link",
            state: "blacklist",
            svgIcon: "lists",
          },
        ],
      },
  
    ];
    this.CommunityMenu = [ 
      {
        name: "Home",
        type: "link",
        tooltip: "Overview",
        svgIcon: "home1",
        state: `Home`,
      },
      {
        name: "Camera records",
        type: "link",
        tooltip: "Camera requests",
        state: "camera-requests",
        svgIcon: "camera",
      }, 
      {
        name: "System Configuration",
        type: "dropDown",
        tooltip: "System_Configuration",
        state: "System_Configuration",
        svgIcon: "ulb_menu",
        sub: [
          {
            name: "Users",
            type: "link",
            svgIcon: "usersNav",
            state: "users",
          },
          {
            name: "Roles",
            type: "link",
            svgIcon: "ulb_partnership_1",
            state: "roles",
          },
          {
            name: "Sites",
            type: "link",
            state: "sites",
            svgIcon: "sites",
          }
        ],
      },
      {
        name: "Access Control",
        type: "dropDown",
        tooltip: "access control",
        state: "access_control",
        svgIcon: "ulb_menu",
        sub: [
          ...(this.isSecurity)?[{
            name: "Validated List",
            type: "link",
            state: "validatedlist",
            svgIcon: "lists",
          }]:[], 
          {
            name: "WhiteList",
            type: "link",
            state: "whitelist",
            svgIcon: "lists",
          },
          {
            name: "Blacklist",
            type: "link",
            state: "blacklist",
            svgIcon: "lists",
          },
        ],
      },
  
    ];
    this.iconOverviewMenu = [
  
      {
        name: "Access Control",
        type: "dropDown",
        tooltip: "access control",
        state: "tenant",
        svgIcon: "ulb_menu",
        sub: [  
          {
            name: "WhiteList",
            type: "link",
            state: "",
            svgIcon: "lists",
          },
          {
            name: "Blacklist",
            type: "link",
            state: "blacklists",
            svgIcon: "lists",
          },
        ],
      },
  
    ];
    this.AccountantMenu= [
      {
        name: "Analytics",
        type: "link",
        tooltip: "Analytics",
        state: "analytics",
        svgIcon: "analytic",
      },
  
    ]; 
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType='public-menu',isSecurity?) { 
    if (isSecurity !== undefined) {
      this.isSecurity = isSecurity;
    }
    switch (menuType) {
      case 'overview-menu':
        this.menuItems.next(this.iconOverviewMenu);
        break;
      case 'whole-menu':
        this.menuItems.next(this.wholeIconMenu);
        break;
      case 'public-menu': 
          this.menuItems.next(this.iconMenu);
        break;
      case 'vip-menu':
        this.menuItems.next(this.vipIconMenu);
        break;
      case 'accountant-menu':
        this.menuItems.next(this.AccountantMenu);
        break;
      case 'community-menu':
        this.menuItems.next(this.CommunityMenu);
        break;
      default:
        this.menuItems.next(this.iconMenu);
    }
    
  } 
}
