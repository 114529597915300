import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ApiService } from "./shared/services/api.service";
import { RoutePartsService } from "./shared/services/route-parts.service";

import { filter, takeUntil } from "rxjs/operators";
import { UILibIconService } from "./shared/services/ui-lib-icon.service";
import { LocalStoreService } from "./shared/services/local-store.service";
import { AuthService, GenericError, User } from "@auth0/auth0-angular";
import { Observable, timer } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SingleTonService } from "./shared/services/single-ton.service";
import { Store } from "@ngxs/store";
import { RoleState, Role, User as userAuth } from './core'

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = "Limitless";
  pageTitle = "";
  JWT_TOKEN = "JWT_TOKEN";
  REF_JWT_TOKEN = "REF_JWT_TOKEN";
  APP_USER = "USER";
  decodeToken;
  pathName = "";
  site = "";
  static publicData;
  user: User = {};
  role;
  isAuthenticated: boolean = false;
  public error$: Observable<any> = this.auth.error$;

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private ls: LocalStoreService,
    private auth: AuthService,
    private translate: TranslateService,
    private singleTon: SingleTonService,
    private store: Store,
  ) {
    this.auth.error$.subscribe();
    this.iconService.init();
    this.auth.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      if (isAuthenticated) {
        this.auth.user$.subscribe((user) => {
          this.auth.getAccessTokenSilently().subscribe(token => {
            ApiService.Token = token;
            this.ls.setItem('TOKEN', token)
          })
          this.ls.setItem(this.APP_USER, user);
          ApiService.org_id = user.org_id;
          this.ls.setItem('org_id', user.org_id)
          ApiService.sub = user.sub;
          this.user = user;
          this.singleTon.user = user;
          this.store.dispatch(new userAuth.AddUserFormSubmitted({ user: user })); 
          this.checkRole(); 
          this.checkSite()
        });
        this.getFromLocalStorageToSingleTon();
      } else {
        this.ls.clear();
        this.router.navigateByUrl("sessions/signin");
      }
    });
  }
  checkRole() {
    if (!this.ls.getItem('Role') || this.ls.getItem('Role')?.length == 0) {
      this.role = this.store.select(RoleState.role).subscribe(res => {
        this.role = res.Roles;
        if (this.role.length === 0) {
          this.store.dispatch(new Role.FetchRoles({
            orgId: this.user?.org_id,
            userId: this.user?.sub
          })).subscribe(res => {
            this.role = res?.role?.Roles;
            this.ls.setItem('Role', this.role)
          });
        }
      });
    } else {
      this.store.dispatch(new Role.AddRoleFormSubmitted({ Role: this.ls.getItem('Role') }))
      this.role = this.ls.getItem('Role')
    }
  }
  getFromLocalStorageToSingleTon(){
    if(!!!this.singleTon?.type){
      this.singleTon.type = this.ls.getItem('typeData')?.toUpperCase()
    }
  }
  checkSite(){
    if(!this.singleTon.site?.name){
      this.singleTon.site = this.ls.getItem('SITE')
    }
  }
  ngOnInit() {
    this.changePageTitle();
    timer(0).pipe(takeUntil(this.error$)).subscribe((res) => {
      if (res > 0) {
        this.auth.logout()
        this.router.navigateByUrl('/sessions/signin');
      }
    });
  }

  ngAfterViewInit() { }

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) {
          return this.title.setTitle(this.appTitle);
        }
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) {
          return this.title.setTitle(this.appTitle);
        }
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }

}
