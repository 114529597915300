<div dir="ltr" [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
    <div class="cancelBox">
        <button *ngIf="checkbox" mat-button class="warn" (click)="clearSelection()">Cancel</button>
      </div>
    <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
            class="mat-elevation-z0" [ngClass]="{'small':columnTitlesCopy.length>20}">
            <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplayCopy; let i = index">
                <th mat-header-cell *matHeaderCellDef>
                    <span *ngIf="columnTitlesCopy[i] !=='IP Address' && columnTitlesCopy[i] !=='Barrier IP'">
                        {{columnTitlesCopy[i]?.replace('_',' ')| titlecase}}

                    </span>
                    <span *ngIf="columnTitlesCopy[i] ==='IP Address' || columnTitlesCopy[i] ==='Barrier IP'">
                        {{columnTitlesCopy[i]?.replace('_',' ')}}

                    </span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div
                        *ngIf="((i != columnsToDisplayCopy.length - 1) && (ActionsBtns?.length>0))||((i != columnsToDisplayCopy.length ) && (ActionsBtns?.length==0))">
                        <span *ngIf="column !=='img'  && (column !=='active' || !activeGate)"
                            [matTooltip]="element[column]" [ngClass]="{'comment':column ==='comment'}"
                            (click)="clickOncolumn(element[column],column)">
                            <span *ngIf="checkColumnIfLink(column)" else>
                                <a class="link" [routerLink]="getTheLink(column,element[column])" [queryParams]="getTheParam(column)">{{element[column] }}</a>
                            </span>
                            <span *ngIf="!checkColumnIfLink(column)">
                                {{element[column] }} 
                            </span>
                        </span>
                        <span *ngIf="column !=='img' && column ==='active' && activeGate" [matTooltip]="element[column]"
                            (click)="clickOncolumn(element[column],column)">
                            <span *ngIf="element[column] ==='Online'" style="color: '#78c071'"> {{element[column]
                                }}</span>
                            <span *ngIf="element[column] ==='Offline'" style="color: '#eb6a4e'"> {{element[column]
                                }}</span>

                        </span>
                        <span *ngIf="column ==='img'">
                            <img [src]="element[column]" alt="Image" width="70px"
                                style="max-width:70px !important;height:30px;border-radius: 3px;">
                        </span>
                    </div>
                    <div *ngIf="i == columnsToDisplayCopy.length - 1 && !checkbox" fxLayout="row"
                        [ngStyle]="isMobile?{'padding': '10px 20px','gap':'10px'}:{}"
                        [ngClass]="{'hide':ActionsBtns.length===0,'showIcone':showIcone}"
                        [fxLayoutAlign]="ActionsBtns?.length>1?'space-between center':'center center'">
                        <button *ngFor="let action of ActionsBtns; let i = index;"
                            (click)="TriggerAction(action, element)" class="btn-with-icon-action"
                            [ngStyle]="showIcone?{'display':'flex','gap':'15px','justify-content':'center','align-items':'center'}:{}">
                            <span [matTooltip]="action.text">
                                <img width="20" height="20" [srcset]="action.icon" [alt]="action.text + 'alt'">
                            </span>
                        </button>
                    </div>
                    <!-- <div *ngIf="checkbox && i == columnsToDisplayCopy.length - 1" fxLayout="row" fxLayoutAlign="center center">
                        <button
                            class="btn-with-checkbox">
                            <mat-checkbox  (click)="TriggerSelect(element)" matTooltip="Select this row"> 
                            </mat-checkbox>
                        </button>
                    </div> -->
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'more'">
                <th mat-header-cell *matHeaderCellDef>
                    More
                </th>
                <td mat-cell *matCellDef="let row;let element; "
                    (click)="expandedElement = expandedElement === element ? null : element">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="select" *ngIf="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox [(ngModel)]="isSelectedAll" matTooltip="Select All Rows"  (change)="selectAll($event)"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox [(ngModel)]="row.isChecked" (change)="selectRows($event, row)"></mat-checkbox>
                </td>
              </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="columnsToDisplayCopy.length">
                    <div class="example-element-detail" [@detailExpand]="
                      element == expandedElement ? 'expanded' : 'collapsed'
                    ">
                        <div *ngFor="let item of element | keyvalue"
                            [style]="{ order: getIndex(item.key) + '!important' }">
                            <div *ngIf="isRowDisplayed(item.key) == true" class="custom-expanded-items no-padding">
                                <mat-grid-list style="width: 100%" cols="2" rowHeight="12px">
                                    <mat-grid-tile>
                                        <p class="custom-expanded-title">
                                            {{ columnTitles[getIndex(item.key)]?.replace('_',' ') | titlecase}}
                                        </p>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <p class="custom-expanded-value"
                                            *ngIf="columnTitles[getIndex(item.key)] !=='img'">
                                            {{item.value}}
                                        </p>
                                        <span *ngIf="columnTitles[getIndex(item.key)] ==='img'">
                                            <img [src]="item.value" alt="Image"
                                                style="max-width:50px !important;border-radius: 3px;">
                                        </span>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tbody>
                <tr mat-header-row *matHeaderRowDef="coumnsToDisplayCopyWithSelect"></tr>
                <tr mat-row *matRowDef="let element; columns: coumnsToDisplayCopyWithSelect;"
                    class="example-element-row">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                    class="example-detail-row row-mobile-expand"></tr>
                <tr mat-row *matNoDataRow>
                    <td class="mat-cell" [colSpan]="columnsToDisplay?.length">
                        No Data Found
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="numOfPages-1 >= 0 && tableData.filteredData.length>0" class="custom-footer">
        <div class="content charts-content" fxLayout="row" fxLayoutGap="16px grid"
            [fxLayoutAlign]="numOfPages >1?'space-between center':'end center'">
            <div *ngIf="numOfPages >1" class="wrapper web-paginator" [ngClass]="{ 'mobile-paginator': isMobile }"
                fxFlex="50" fxFlex.xs="75" fxFlex.sm="75" fxFlex.md="75" fxFlex.lg="50">
                <button class="arrow-color" mat-icon-button (click)="getPrePage()"
                    *ngIf="numOfPages+1 > 1 && currentPageIndex >= 1" matTooltip="Previous">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <div *ngIf="numbers.length > numberOfPagesShown + 1" class="web-paginator">
                    <div *ngFor="let page of numbers">
                        <button *ngIf="page < numberOfPagesShown - 1" mat-button (click)="SelectPage(page)" [ngClass]="{
                'selected-page': pageSelected == page,
                'unselected-page': pageSelected != page
              }">
                            {{ page+1 }}
                        </button>
                        <!-- ///// Start of mid range ///// -->
                        <button mat-button class="unselected-page"
                            *ngIf="page == numberOfPagesShown - 1 && pageSelected > page">
                            ...
                        </button>
                        <button mat-button (click)="SelectPage(page)" [ngClass]="{
                'selected-page': pageSelected == page,
                'unselected-page': pageSelected != page
              }" *ngIf="page == numberOfPagesShown - 1 && pageSelected <= page">
                            {{ page+1 }}
                        </button>
                        <!-- ///// End of mid range ///// -->
                        <button mat-button class="unselected-page" *ngIf="
                page == numberOfPagesShown &&
                page != pageSelected &&
                pageSelected <= page
              ">
                            ...
                        </button>
                        <button (click)="SelectPage(page)" *ngIf="page >= numberOfPagesShown && page == pageSelected"
                            mat-button [ngClass]="{
                'selected-page': pageSelected == page,
                'unselected-page': pageSelected != page
              }">
                            {{ page+1 }}
                        </button>
                        <button mat-button class="unselected-page"
                            *ngIf="page >= numberOfPagesShown && page == pageSelected">
                            ...
                        </button>
                        <button *ngIf="page == numbers.length" mat-button (click)="SelectPage(page)" [ngClass]="{
                'selected-page': pageSelected == page,
                'unselected-page': pageSelected != page
              }">
                            {{ page+1 }}
                        </button>
                    </div>
                </div>
                <div *ngIf="numbers.length <= numberOfPagesShown" class="web-paginator"
                    [ngClass]="{ 'mobile-paginator': isMobile }">
                    <div *ngFor="let page of numbers">
                        <button *ngIf="page < numberOfPagesShown" mat-button (click)="SelectPage(page)" [ngClass]="{
                'selected-page': pageSelected == page,
                'unselected-page': pageSelected != page
              }">
                            {{ page+1 }}
                        </button>
                    </div>
                </div>
                <button mat-icon-button class="arrow-color" (click)="getNxtPage()"
                    *ngIf="numOfPages-1 > 1 && currentPageIndex < numOfPages-1" matTooltip="Next">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
            <div *ngIf="tableData.filteredData.length>4" class="wrapper" fxFlex="25" fxFlex.xs="25" fxFlex.sm="25"
                fxFlex.md="25" fxFlex.lg="25" fxLayout="row" fxLayoutAlign="end center">
                <mat-select class="pages-select" bindLabel="label" bindValue="size" [(ngModel)]="selectedSize"
                    (selectionChange)="changePageSize($event)">
                    <mat-option *ngFor="let item of sizes" [value]="item.size">{{item.size}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>