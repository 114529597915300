<div class="sidebar-panel " [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold full-height">



            <!-- App Logo -->
            <div class="branding">
                <img srcset="assets/images/Logo1.svg" src="assets/images/Logo1.svg" width="150" height="50"
                    alt="Logo img" class="app-logo"> 
            </div>

            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem"></app-sidenav>
        </div>
    </div>
</div>