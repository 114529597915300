import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'app/shared/services/api.service';
import { MapsAPILoader } from '@agm/core';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-new-site',
  templateUrl: './new-site.component.html',
  styleUrls: ['./new-site.component.scss'],
  encapsulation: ViewEncapsulation.None,

})

export class NewSiteComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() isTablet: boolean;
  @Input() isLaptop: boolean;
  @Input() isScreen: boolean;
  @Output() refreshSite = new EventEmitter<any>();
  timezones: TimezoneInfo[] = moment.tz.names().map(name => ({
    name,
    offset: moment.tz(name).format('Z')
  }));
  address = '';
  gatesActivity: { active: any; emails: any; };
  cashierShiftReport: { active: any; emails: any; };
  blacklistedCarsReport: { active: any; emails: any; };
  currencies: string[] = ['USD', 'EUR', 'GBP', 'JPY', 'CAD', 'AUD', 'CHF', 'CNY', 'INR', 'BRL', 'KRW', 'RUB', 'MXN', 'ZAR', 'TRY', 'SAR', 'KWD', 'AED', 'QAR', 'BHD', 'JOD'];
  emailGates = '';
  emailCashier = '';
  emailBlacklist = '';
  constructor(private http: HttpClient,
    private toster: ToastrService,
    private api: ApiService, private mapsAPILoader: MapsAPILoader,
    private toastr: ToastrService
  ) { }
  apiKey = 'AIzaSyBu1cpRbk3NflsJ7Aiy_9afYUpMrwXCrZo'
  lat: number = 0;
  lng: number = 0;
  parkingType = [];
  timezoneAll: TimezoneInfo;
  public FormControls = {
    name: new FormControl("", [Validators.required]),
    parkingType: new FormControl("", [Validators.required]),
    currency: new FormControl("", [Validators.required]),
    long: new FormControl('', [Validators.required]),
    logoUrl: new FormControl(''),
    lat: new FormControl('', [Validators.required]),
    timezoneOffset: new FormControl("", [Validators.required]),
    timezone: new FormControl("", [Validators.required]),
    start: new FormControl({ value: 0 }, [Validators.required]),
    activeHour: new FormControl(false, [Validators.required]),
    end: new FormControl({ value: 0 }, [Validators.required]),
    hoursPerVisit: new FormControl("", [Validators.required]),
    FreeVisitsCount: new FormControl("", [Validators.required]),
    rectifyPublicAnomaly: new FormControl(false, [Validators.required]),
    action: new FormControl('', [Validators.required]),
    emailsGates: new FormControl([]),
    activeGates: new FormControl(false),
    emailsCashier: new FormControl([]),
    activeCashier: new FormControl(false),
    emailsBlacklist: new FormControl([]),
    activeBlacklist: new FormControl(false),
  };
  loading = false;
  form: FormGroup = new FormGroup(this.FormControls);
  BlacklistActionTypes: { value: string, desc: string }[]

  async ngOnInit() {
    this.parkingType = [
      {
        id: "ININ",
      },
      {
        id: "INOUT",
      },
    ];
    this.BlacklistActionTypes = [
      {
        value: 'BLOCK',
        desc: 'Block'
      },
      {
        value: 'REPORT',
        desc: 'Report'
      }, {
        value: 'BLOCK_REPORT',
        desc: 'Block & Report'
      }
    ]
    try {
      const coordinates = await this.getCurrentLocation();
      const address = await this.getAddressFromCoordinates(coordinates.latitude, coordinates.longitude);
      this.address = address;
      console.log(address)
    } catch (error) {
      console.error('Error getting current location:', error);
    }
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
  }
  toggle(event?) {
    this.form.controls.activeHour.setValue(!event)
    // if (!this.form.controls.activeHour.value) {
    //   this.form.controls.start.enable({ onlySelf: true, emitEvent: true })
    //   this.form.controls.end.enable({ onlySelf: true, emitEvent: true });
    //   this.form.updateValueAndValidity()
    // }
    // if (this.form.controls.activeHour.value) {
    //   console.log('disable')
    //   this.form.controls.start.disable({ onlySelf: true, emitEvent: true })
    //   this.form.controls.end.disable({ onlySelf: true, emitEvent: true });
    //   this.form.updateValueAndValidity()
    // }
  }
  toggleRectifyPublicAnomaly(event) {
    this.form.controls.rectifyPublicAnomaly.setValue(!event)
  }
  toggleActiveGates(event) {
    this.form.controls.activeGates.setValue(!event)
  }
  toggleActiveCashier(event) {
    this.form.controls.activeCashier.setValue(!event)
  }
  toggleActiveBlackList(event) {
    this.form.controls.activeBlacklist.setValue(!event)
  }
  setForm() {
    this.form.controls.timezone.setValue(this.timezoneAll?.name)
    this.form.controls.timezoneOffset.setValue(this.converNumber(this.timezoneAll?.offset.slice(0, this.timezoneAll?.offset.indexOf(':'))))
  }
  addEmail(event: Event) {
    event.preventDefault();
    if (this.emailGates.trim() !== '' && this.isValidEmail(this.emailGates.trim())) {
      let gatesActivity = Array.from(this.form.controls.emailsGates.value);
      if (!this.repeatedEmail(this.emailGates.trim(), gatesActivity)) {
        console.log(this.emailGates.trim())
        gatesActivity.push(this.emailGates.trim())
        this.form.controls.emailsGates.setValue(gatesActivity);
        this.emailGates = '';
      }
      else {
        this.toastr.error(`${this.emailGates.trim()} is repeated email.`, 'Repeated email', { timeOut: 4000 })
      }
    } else {
      this.toastr.error(`${this.emailGates.trim()} is not a valid email.`, 'Invalid email', { timeOut: 4000 })
    }
  }
  addEmailCashier(event: Event) {
    event.preventDefault()
    console.log(event)
    if (this.emailCashier.trim() !== '' && this.isValidEmail(this.emailCashier.trim())) {
      let Cashier = Array.from(this.form.controls.emailsCashier.value);
      if (!this.repeatedEmail(this.emailCashier.trim(), Cashier)) {
        console.log(this.emailCashier.trim())
        Cashier.push(this.emailCashier.trim())
        this.form.controls.emailsCashier.setValue(Cashier);
        this.emailCashier = '';
      }
      else {
        this.toastr.error(`${this.emailCashier.trim()} is repeated email.`, 'Repeated email', { timeOut: 4000 })
      }
    } else {
      this.toastr.error(`${this.emailCashier.trim()} is not a valid email.`, 'Invalid email', { timeOut: 4000 })
    }
  }
  addEmailBlacklist(event: Event) {
    event.preventDefault()
    if (this.emailBlacklist.trim() !== '' && this.isValidEmail(this.emailBlacklist.trim())) {
      let Blacklist = Array.from(this.form.controls.emailsBlacklist.value);
      if (!this.repeatedEmail(this.emailBlacklist.trim(), Blacklist)) {
        Blacklist.push(this.emailBlacklist.trim())
        this.form.controls.emailsBlacklist.setValue(Blacklist);
        this.emailBlacklist = '';
      }
      else {
        this.toastr.error(`${this.emailBlacklist.trim()} is repeated email.`, 'Repeated email', { timeOut: 4000 })
      }
    } else {
      this.toastr.error(`${this.emailBlacklist.trim()} is not a valid email.`, 'Invalid email', { timeOut: 4000 })
    }
  }
  deleteEmailGates(email) {
    let gatesActivity = Array.from(this.form.controls.emailsGates.value);
    gatesActivity = gatesActivity.filter(ele => ele != email);
    this.form.controls.emailsGates.setValue([])
    this.form.controls.emailsGates.setValue(gatesActivity)
  }
  deleteEmailCashier(email) {
    let Cashier = Array.from(this.form.controls.emailsCashier.value);
    Cashier = Cashier.filter(ele => ele != email);
    this.form.controls.emailsCashier.setValue(Cashier)
  }
  deleteEmailBlacklist(email) {
    let Blacklist = Array.from(this.form.controls.emailsBlacklist.value);
    Blacklist = Blacklist.filter(ele => ele != email);
    this.form.controls.emailsBlacklist.setValue(Blacklist)
  }
  repeatedEmail(email: string, Emails): boolean {
    return Emails.includes(email)
  }
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  isEmailsNull(active, emails: Array<any> = []) {
    return (active && emails.length == 0)
  }
  onAutocompleteSelected(event: PlaceResult) {
    console.log('onAutocompleteSelected: ', event);
  }
  onMarkerDragEnd(event: any) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getAddress(this.lat, this.lng);
      });
    }
  }
  onMapClick(event: any) {
    console.log(event)
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }
  getAddress(latitude: number, longitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: latitude, lng: longitude };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          console.log(this.address)
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }
  getCurrentLocation(): Promise<Coordinates> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position.coords),
        (error) => reject(error)
      );
    });
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.lat = location.latitude;
    this.lng = location.longitude;
    this.form.controls.lat.setValue(this.lat);
    this.form.controls.long.setValue(this.lng);
  }
  converNumber(str: string): Number {
    let num = 0
    if (str.startsWith('+')) {
      num = parseInt(str.substring(1), 10); // Remove the leading '+' and parse the rest
      num = num >= 0 ? num : -num; // Ensure the sign is preserved
    } else {
      num = parseInt(str, 10);
    }
    return num

  }
  submit() {
    this.gatesActivity = {
      active: this.form.controls.activeGates.value,
      emails: this.form.controls.emailsGates.value,
    }
    this.cashierShiftReport = {
      active: this.form.controls.activeCashier.value,
      emails: this.form.controls.emailsCashier.value,
    }
    this.blacklistedCarsReport = {
      active: this.form.controls.activeBlacklist.value,
      emails: this.form.controls.activeBlacklist.value ? this.form.controls.emailsBlacklist.value : [],
    } 
    this.api
      .RegisterSite(
        this.form.controls.name.value,
        this.form.controls.parkingType.value,
        this.form.controls.currency.value,
        this.form.controls.long.value,
        this.form.controls.lat.value,
        this.form.controls.timezone.value,
        this.form.controls.timezoneOffset.value,
        this.form.controls.activeHour.value,
        this.form.controls.start.value,
        this.form.controls.end.value,
        this.form.controls.hoursPerVisit.value,
        this.form.controls.FreeVisitsCount.value,
        this.form.controls.rectifyPublicAnomaly.value,
        this.form.controls.action.value,
        this.gatesActivity,
        this.cashierShiftReport,
        this.blacklistedCarsReport
      )
      .subscribe((res) => {
        console.log(res);
        if (res.status == 201) {
          this.loading = false;
          this.toster.success("Gate Added successfuly", "Ok", { timeOut: 4000 });
          this.AddtoSite();
        } else {
          this.loading = false;
          this.toster.error(
            res?.errorCode ? res?.errorCode : "Un expected error",
            "Error!!",
            { timeOut: 1000 }
          );
        }
      });
  }
  AddtoSite() {
    this.refreshSite.emit();
  }
  async getAddressFromCoordinates(latitude: number, longitude: number): Promise<string> {
    const response = await this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.apiKey}`
    ).toPromise();
    return response.results[0].formatted_address;
  }
}
interface Coordinates {
  latitude: number, longitude: number
}
export interface TimezoneInfo {
  name: string, offset: string
}