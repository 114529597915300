import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppLogoutComponent } from './app-logout.component';
interface LogoutData {
  title?: string,
  message?: string
}

@Injectable()
export class AppLogoutService {
  constructor(private dialog: MatDialog) { }

  public confirm(data:LogoutData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<AppLogoutComponent>;
    dialogRef = this.dialog.open(AppLogoutComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }
}