import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { LayoutService } from "app/shared/services/layout.service";
import { Page } from "../../models/common.model";
import { DatatableComponent } from "@swimlane/ngx-datatable";
@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() page: Page = {
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0,
  };
  @Input() minWidthClose = "";
  @Input() minWidthOpen = "";
  @Input() loading = false;
  @Output() paginate: EventEmitter<any> = new EventEmitter<any>();
  rows;
  hide = false;
  @Input() action;
  @Input() title = "";
  @Input() columns = [];
  @Input() ActionsBtns = [];
  @Output() edit = new EventEmitter<any>(); //
  @Output() editPermissions = new EventEmitter<any>(); //
  @Output() view = new EventEmitter<any>(); //
  @Output() assign = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() rowSelected = new EventEmitter<any>();
  @Input() isMobile: boolean = false;
  @Input() isTablet: boolean = false;
  @Input() isLaptop: boolean = false;
  @Input() isScreen: boolean = false;
  @Input() site: any;
  // @Input() Type: any;
  SelectRow(element: any) {
    this.rowSelected.emit(element);
  }

  TriggerAction(action: Action, row: any) {
    this.SelectRow(row);
    switch (action.key) {
      case "edit_permissions":
        this.editPermissions.emit();
        break;
      case "edit":
        this.edit.emit();
        break;
      case "view":
        this.view.emit();
        break;
      case "delete":
        this.delete.emit(row);
        break;
      case "assign":
        this.assign.emit(row);
        break;
    }
  }
  @ViewChild("myTable") table: DatatableComponent;
  constructor(
    public layouts: LayoutService,
  ) { }
  ngOnInit(): void { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.expendRows();
    }, 500);
  }
  ngOnChanges(): void {
    setTimeout(() => {
      this.expendRows();
    }, 500);
  }
  onHide(row) {
    // console.log(row);
    this.rows = row;
    this.hide = !this.hide;
  }
  public expandedRows = [];
  expendRows(): void {
    this.loading = true;
    this.table?._rows?.forEach((x) => {
      this.table.rowDetail.toggleExpandRow(x);
      this.table.rowDetail.expandAllRows();
    });
    setTimeout(() => {
      this.loading = false;
    }, 500)
  }

  setPage(event) {
    this.paginate.emit({
      offset: event.offset?.offset ? event.offset.offset : event.offset,
    });
    setTimeout(() => {
      this.expendRows();
    }, 500);
  }
  replace(str: string) {
    return str.replace("_", " ").toUpperCase();
  }
  getRowClass(row: any) {
    // console.log(this.Type)
    return {
      whiteList:
        !row.isWhitelisted &&
        row["isWhitelisted"] !== undefined &&
        row["site"] !== "Tilal Al Ghaf - Retail Hub",
    };
  }
}
export interface Action {
  key: string;
  text: string;
  icon: string;
}
