import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppInputCloseComponent } from './app-Input-close.component';
interface Data {
  title?: string,
  message?: string,
  value?: string,
  placeholder?: string
}

@Injectable()
export class AppInputCloseService {
  constructor(private dialog: MatDialog) { }

  public confirm(data: Data = { value: '' }): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.value = data.value || '';
    data.placeholder = data.placeholder || 'Write Here ...';
    let dialogRef: MatDialogRef<AppInputCloseComponent>;
    dialogRef = this.dialog.open(AppInputCloseComponent, {
      width: '380px',
      disableClose: true,
      data: { title: data.title, message: data.message, value: data.value, placeholder: data.placeholder }
    });
    return dialogRef.afterClosed();
  }
}