<div class="app-admin-wrap moreHeight" [ngClass]="{'lap':isLaptop,'mob':isMobile,'scr':isScreen,'tab':isTablet}">
    <div class="login-box">
        <h2>New Site</h2>
        <form [formGroup]="form" fxLayout="row wrap" fxLayoutAlign="space-between center" (ngSubmit)="submit()">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="contanier" fxLayout.lt-sm="column">
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-16 mt-12">
                    <h6 fxFlex="100" class="mb-0">General Configurations</h6>
                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width">
                            <input #siteName matInput type="text" minlength="2" name="name" formControlName="name"
                                placeholder="Site Name">
                        </mat-form-field>
                    </div>
                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width">
                            <mat-select formControlName="parkingType" aria-placeholder="Parking Type"
                                placeholder="Parking Type">
                                <mat-option *ngFor="let par of parkingType" [value]="par.id">
                                    {{par.id}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width">
                            <mat-select (selectionChange)="setForm()" [(ngModel)]="timezoneAll" placeholder="TimeZone"
                                [ngModelOptions]="{standalone:true}">
                                <mat-option *ngFor="let timezone of timezones" [value]="timezone">
                                    {{ timezone.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width">

                            <mat-select formControlName="currency" aria-placeholder="select currency"
                                placeholder="select currency">
                                <mat-option *ngFor="let currency of currencies" [value]="currency">
                                    {{currency}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="48" fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-16 mt-12">
                    <h6 fxFlex="100" fxLayout="row " fxLayoutAlign="start center" class="mb-0">Working Hours
                        Configurations
                        <mat-slide-toggle #activeHour (click)="toggle(activeHour.checked)" (toggleChange)="toggle()"
                            formControlName="activeHour" labelPosition="before"></mat-slide-toggle>
                    </h6>
                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width rv">
                            <div class="cover" *ngIf="!activeHour.checked">
                            </div>
                            <input #start matInput type="number" minlength="2" name="name" formControlName="start"
                                placeholder="Opening Hour">
                        </mat-form-field>
                    </div>
                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width">
                            <input #end matInput type="number" minlength="2" name="name" formControlName="end"
                                placeholder="Closing Hour">
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="48" fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-16 mt-12">
                    <h6 fxFlex="100" class="mb-0">Blacklist Configurations</h6>
                    <div fxFlex="100" class="pr-16">
                        <mat-form-field class="full-width">
                            <mat-select formControlName="action" placeholder="">
                                <mat-option *ngFor="let actionConfig of BlacklistActionTypes"
                                    [value]="actionConfig.value">
                                    {{ actionConfig.desc }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-16 mt-12">
                    <h6 fxFlex="100" class="mb-0">Gym Area Configurations</h6>
                    <div fxFlex="48" class="pr-16">
                        <!-- <h4>Gym Area Configurations</h4> -->
                        <mat-form-field class="full-width">
                            <input #siteName matInput type="number" minlength="2" name="name"
                                formControlName="hoursPerVisit" placeholder="Hours Per Visit">
                        </mat-form-field>
                    </div>
                    <div fxFlex="48" class="pr-16">
                        <mat-form-field class="full-width">
                            <input #siteName matInput type="number" minlength="2" name="name"
                                formControlName="FreeVisitsCount" placeholder="Free Visits Count">
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-16 mt-12">
                    <div fxFlex="48" class="pr-16">
                        <mat-slide-toggle #rectifyPublicAnomalyCheck formControlName="rectifyPublicAnomaly"
                            (click)="toggleRectifyPublicAnomaly(rectifyPublicAnomalyCheck.checked)"
                            labelPosition="before">Allow
                            Exit for unrecognized vehicles</mat-slide-toggle>
                    </div>

                </div>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-16 mt-12">
                    <h6 fxFlex="100" class="mb-0">Notifications Configurations</h6>
                    <div fxFlex="48" class="pr-16">
                        <mat-slide-toggle formControlName="activeGates"
                            (click)="toggleActiveGates(activeGatesValue.checked)" #activeGatesValue
                            labelPosition="before">Gate
                            notifications</mat-slide-toggle>
                        <div class="emails" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <span class="email" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
                                *ngFor="let email of form.controls.emailsGates?.value">
                                {{email}}
                                <mat-icon fxLayout="row" fxLayoutAlign="center center"
                                    (click)="deleteEmailGates(email)">cancel</mat-icon>
                            </span>
                        </div>

                        <input matInput placeholder="Enter Your Email and Press Enter to Add Another"
                            [ngModelOptions]="{standalone: true}" *ngIf="activeGatesValue.checked"
                            (keyup.enter)="addEmail($event)" [(ngModel)]="emailGates" />
                    </div>
                    <div fxFlex="48" class="pr-16">
                        <mat-slide-toggle formControlName="activeCashier"
                            (click)="toggleActiveCashier(activeCashierValue.checked)" #activeCashierValue
                            labelPosition="before">Cashier Shift Report Notifications</mat-slide-toggle>
                        <div class="emails" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <span class="email" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
                                *ngFor="let email of form.controls.emailsCashier?.value">
                                {{email}}
                                <mat-icon fxLayout="row" fxLayoutAlign="center center"
                                    (click)="deleteEmailCashier(email)">cancel</mat-icon>
                            </span>
                        </div>

                        <input matInput placeholder="Enter Your Email and Press Enter to Add Another"
                            [ngModelOptions]="{standalone: true}" *ngIf="activeCashierValue.checked"
                            (keyup.enter)="addEmailCashier($event)" [(ngModel)]="emailCashier" />
                    </div>
                    <div fxFlex="100" fxFlex.xs="100" ngClass.gt-xs="pr-16" ngClass.xs="pr-0">
                        <div class="full-width box-site">

                            <mat-slide-toggle class="toggle" formControlName="activeBlacklist" #activeBlacklistValue
                                labelPosition="before">Blacklist Report Notifications</mat-slide-toggle>
                        </div>
                        <div class="emails" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <span class="email" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
                                *ngFor="let email of form.controls.emailsBlacklist?.value">
                                {{email}}
                                <mat-icon fxLayout="row" fxLayoutAlign="center center"
                                    (click)="deleteEmailBlacklist(email)">cancel</mat-icon>
                            </span>
                        </div>
                        <div class="full-width box-site" *ngIf="activeBlacklistValue.checked">
                            <mat-label *ngIf="emailBlacklists.value">Emails</mat-label>

                            <input matInput #emailBlacklists
                                placeholder="Enter Your Email and Press Enter to Add Another"
                                [ngModelOptions]="{standalone: true}" (keyup.enter)="addEmailBlacklist($event)"
                                [(ngModel)]="emailBlacklist" />
                        </div>
                    </div>
                </div>

                <div fxFlex="100" class="pr-16">
                    <mat-form-field class="full-width">
                        <input matInput matGoogleMapsAutocomplete type="address" placeholder="Location" name="location"
                            [value]="address" (onAutocompleteSelected)="onAutocompleteSelected($event)"
                            (onLocationSelected)="onLocationSelected($event)">

                    </mat-form-field>
                    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="15" (mapClick)="onMapClick($event)">
                        <agm-marker [latitude]="lat" [longitude]="lng" [label]="siteName.value"
                            (dragEnd)="onMarkerDragEnd($event)"></agm-marker>
                    </agm-map>
                </div>
                <div fxFlex="100" class="mt-16" fxLayout="row" fxLayoutAlign="center center">
                    <button fxFlex="30" type="submit" class="btnOrangS" mat-raised-button color="primary"
                        [disabled]="form.invalid">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>