import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { Site } from '../models/site.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingleTonService {
  isRTL: boolean = false;
  currentLang = 'en';
  type = '';
  States=[];
  organizationServices:string[]=[];
  AllSites=[];
  site:any = {
    siteId: '',
    site: '',
    parkingType: '',
    createdAt: '',
    updatedAt: '',
    entrancesCount: 0,
    exitsCount: 0,
    long: '',
    lat: '',
    logoUrl: '',
    workingHours: undefined,
    gymAreaConfig: undefined,
    notifications: undefined,
    registeredServices: {
      public: false,
      vip: false,
      gym: false,
      reserved: false,
      openReserved: false
    },
    blacklistConfig: {
      action: ''
    }
  };
  user: User = {}
  // isRTL: boolean = true;
  // currentLang = 'ar';
  constructor() { }
  private singleTonData = new Subject<any>();

  public publishData(data: any) {
    this.singleTonData.next(data);
  }

  public getObservable(): Subject<any> {
    return this.singleTonData;
  }
}
