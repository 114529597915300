import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";
@Component({
  selector: "app-logout",
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16 f-10">{{ data.message }}</div>
    <div
      class="btns full-width"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <button
        type="button"
        class="feesBtn"
        mat-button
        (click)="dialogRef.close(false)"
      >
        Cancel
      </button>
      &nbsp;
      <span fxFlex></span>
      <button
        type="button"
        mat-button
        class="feesBtn"
        (click)="dialogRef.close(true)"
      >
        OK
      </button>
    </div>`,
  styles: [`
    ::ng-deep .mat-dialog-container {
      background: #293241 !important;
      color: #fff !important;
      border-radius: 1.5rem !important;
      text-align: center !important;
      & h1{
        font-size:12.5px !important;
      }
  }`]
})
export class AppLogoutComponent {
  constructor(
    public dialogRef: MatDialogRef<AppLogoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}