import { Roles } from "app/shared/models/user.model";

export namespace Role {
    export class AppLoaded {
        static readonly type = "[App] App Loaded";
    }
    export class FetchRoles {
        static readonly type = "[Role API] Fetch Roles of user";
        constructor(public payload: {
            orgId: string,
            userId: string
        }) { }
    }
    export class FetchRolesSuccess {
        static readonly type = "[Role API] Fetch Roles Success";
        constructor(public payload: { Roles: Roles[] }) { }
    }

    export class FetchRolesFailed {
        static readonly type = "[Roles API] Fetch Roles Failed";
        constructor(public payload: { error: any }) { }
    }
    export class AddRoleFormSubmitted {
        static readonly type = "[Add Role Page] Add Role Form Submitted";
        constructor(public payload: { Role: Roles[] }) { }
    }
    export class AddRolesSuccess {
        static readonly type = "[Role API] Add Roles Success";
    }

    export class AddRolesFailed {
        static readonly type = "[Role API] Add Roles Failed";
        constructor(public payload: { error: any }) { }
    }
    export class EditRoleFormSubmitted {
        static readonly type = "[Edit Role Page] Edit Role Item Form Submitted";
        constructor(public payload: { Role: Roles }) { }
    }
    export class EditRolesSuccess {
        static readonly type = "[Role API] Edit Role Success";
        constructor(public payload: { Roles: Roles }) { }
    }

    export class EditRolesFailed {
        static readonly type = "[Roles API] Edit Role Failed";
        constructor(public payload: { error: any }) { }
    }

    export class DeleteRoleInitiated {
        static readonly type = "[Delete Role Page] Delete Role Item Initiated";
        constructor(public payload: { RoleId: string }) { }
    }
    export class DeleteRoleSuccess {
        static readonly type = "[Roles API] Delete Roles Success";
        constructor(public payload: { RoleId: string }) { }
    }

    export class DeleteRoleFailed {
        static readonly type = "[Roles API] Delete Roles Failed";
        constructor(public payload: { error: any }) { }
    }
}