import { Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { SiteStateModel, SitesStateModel } from "./site.model";
import { Site } from './site.action'
import { LocalStoreService } from "app/shared/services/local-store.service";
import { SingleTonService } from "app/shared/services/single-ton.service";
import { tap } from "rxjs/operators";
import { ApiService } from "app/shared/services/api.service";
import { Page } from "app/shared/models/common.model";
@State<SiteStateModel>({
    name: "site",
    defaults: {
        Site: null,
    },
})
@State<SitesStateModel>({
    name: "sites",
    defaults: {
        Sites: [],
    },
})
@Injectable({ providedIn: 'root' })
export class SiteState implements NgxsOnInit {
    page: Page = {
        totalDocs: 0,
        limit: 1000,
        page: 0
    }
    constructor(
        private api: ApiService,
        private singleTon: SingleTonService,
        private ls: LocalStoreService) {
    }
    ngxsOnInit(ctx: StateContext<SiteStateModel | SitesStateModel>) {
        let site = this.ls.getItem('SITE');
        let sites = this.ls.getItem('SITES');
        ctx.patchState({
            Site: site,
            Sites: sites
        });
        this.singleTon.site = site
        this.singleTon.AllSites = sites

    }
    @Selector()
    static site(state: SiteStateModel) {
        return state.Site;
    }
    @Selector()
    static Sites(state: SitesStateModel) {
        return state.Sites;
    }
    @Action(Site.SelectSite)
    SelectSite({ patchState, getState }: StateContext<SiteStateModel>, action: Site.SelectSite) {
        this.singleTon.site = action.payload?.Site
        const state = getState();

        patchState({
            Site: action.payload?.Site,
        });
    }

    @Action(Site.FetchSites)
    FetchSites({ getState, patchState }: StateContext<SitesStateModel>, action: Site.FetchSites) {
        return this.api.getSites('', action.payload.page).pipe(tap((result) => {
            const state = getState();
            let Sites = []
            result?.body?.data.forEach((el, i) => {
                Sites.push({
                    id: el.siteId,
                    site: el.name,
                    currency: el.currency,
                    entrancesCount: el.entrancesCount,
                    exitsCount: el.exitsCount,
                    lat: el.lat,
                    lng: el.long,
                    color: '#ffa500',
                    code: el.code,
                    entrances: el.entrances,
                    exits: el.exits,
                    logoUrl: el.logoUrl,
                    registeredServices: el.registeredServices
                });
            });
            patchState({
                Sites: Sites,
            });
        }));
    }

    @Action(Site.GetSites)
    GetSites({ patchState, getState }: StateContext<SitesStateModel>, action: Site.GetSites) {
        const state = getState();
        patchState({
            Sites: action.payload?.Sites,
        });
    }


}

