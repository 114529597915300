import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-Input-close",
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
  <div mat-dialog-content>
  <!-- <span class="title mb-16 p-12">{{ data.message }}</span> -->

     <div class="full-width ffild">
      <!-- <label>{{data.placeholder}}</label> -->
    <input  [placeholder]="data.placeholder" minlength="5" (change)="changeInput()" [(ngModel)]="data.value">
  </div>
    <div
      class="btns full-width"
      fxLayout="row"
      
      mat-dialog-actions
    >
      <button
        type="button"
        class="feesBtn"
        mat-button
        (click)="close()"
      >
        Cancel
      </button>
      &nbsp;
      <span fxFlex></span>
      <button
        type="button"
        mat-button
        class="feesBtn"
        cdkFocusInitial
        [disabled]="data.value?.length<5"
        [mat-dialog-close]="data.value"
        (click)="dialogRef.close(data.value)"
      >
        OK
      </button>
    </div>
</div>`,
  styles: [`
  .mat-dialog-content{
    min-height: 14vh;
    overflow:hidden;
    padding-top:10px
  } 
  input::placeholder{
    color:#ffffff90
  }
  input{
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    outline:none;
    background:transparent;
    padding:10px 10px;
    width:100%;
    color:#ffffffb5

  }
  .ffild {
    border: 0px !important;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
 .btns{
  justify-content: space-between !important;
 }
    ::ng-deep .mat-dialog-container {
      background: #293241 !important;
      color: #fff !important;
      border-radius: 1.5rem !important;
      text-align: center !important;
      & h1{
        font-size:12.5px !important;
      }
  }`]
})
export class AppInputCloseComponent {
  constructor(
    public dialogRef: MatDialogRef<AppInputCloseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  changeInput() {
    console.log(this.data.value)
  }
  close() {
    this.dialogRef.close(false);
    this.data = {
      value: ''
    }
  }
}