import { User as Users } from "app/shared/models/user.model";

export namespace User {
    export class AppLoaded {
        static readonly type = "[App] App Loaded";
    }
    export class FetchUser {
        static readonly type = "[User API] Fetch User of user";
    }
    export class EditUserFormSubmitted {
        static readonly type = "[Edit User Page] Edit User Item Form Submitted";
        constructor(public payload: { User: Users }) { }
    }
    export class EditUserSuccess {
        static readonly type = "[User API] Edit User Success";
        constructor(public payload: { User: Users }) { }
    }
    export class AddUserFormSubmitted {
        static readonly type = "[Add User Page] Add User Form Submitted";
        constructor(public payload: { user: Users }) { }
    }
    export class EditUserFailed {
        static readonly type = "[User API] Edit User Failed";
        constructor(public payload: { error: any }) { }
    }


}