import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserTypeService } from '../services/user-type.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
    providedIn: 'root'
})
export class AccountantGuard implements CanDeactivate<unknown> {
    constructor(private userType: UserTypeService, private snack: MatSnackBar, private router: Router) { }
    // @ts-ignore
    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const role = this.userType.Accountant();
        if (role) { 
            return false;
        } else { 
            return true;
        }
    }

}